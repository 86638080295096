export default {
  setEvents(state, events) {
    state.events = events;
  },
  setCities(state, cities) {
    state.cities = cities;
  },
  setOrgs(state, orgs) {
    state.orgs = orgs;
  },
  setPatrons(state, patrons) {
    state.patrons = patrons;
  },
  setDocs(state, docs) {
    state.docs = docs;
  },
};
