export default {
  token: (state) => {
    return { access: state.access };
  },
  getUserInfo: (state) => (id) => {
    return state.users.find((el) => el.id == id);
  },
  getCurUserInfo: (state) => {
    return state.curUser;
  },
  getId: (state) => {
    return state.id;
  },
};
