import Vue from "vue";
import Axios from "@/api/axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, Axios);

// импорт точек API
import {
  APIPOINT_ACH_SUBMIT,
  APIPOINT_ACH_LIST,
  APIPOINT_ACH_CORRECT,
  APIPOINT_ACH_CONFIRM,
  APIPOINT_ACH_UNCONFIRM,
  APIPOINT_ACH_REMOVE,
  APIPOINT_GETRATING,
} from "@/api/points";

export const achSubmit = function (data) {
  return Axios.post(APIPOINT_ACH_SUBMIT, data);
};
export const achList = function (filter) {
  return Axios.post(APIPOINT_ACH_LIST, filter);
};
export const achCorrect = function (id, comment) {
  return Axios.post(APIPOINT_ACH_CORRECT, { id, comment });
};
export const achConfirm = function (id) {
  return Axios.post(APIPOINT_ACH_CONFIRM, { id });
};
export const achUnconfirm = function (id) {
  return Axios.post(APIPOINT_ACH_UNCONFIRM, { id });
};
export const achRemove = function (id) {
  return Axios.post(APIPOINT_ACH_REMOVE, { id });
};
export const getRating = function (id) {
  return Axios.post(APIPOINT_GETRATING, { id });
};
