import getters from "@/store/modules/lists/getters";
import mutations from "@/store/modules/lists/mutations";
import actions from "@/store/modules/lists/actions";
export default {
  state: {
    levels: [],
    forms: [],
    types: [],
    results: [],
    directions: [],
    disciples: [],
    children: [],
    events: [],
  },
  getters,
  mutations,
  actions,
};
