import Vue from "vue";
import VueRouter from "vue-router";
import {
  ROUTE_INDEX,
  ROUTE_AUTH,
  ROUTE_DASH,
  ROUTE_EDITFORM,
  ROUTE_RATING,
} from "@/router/routes.type";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: ROUTE_INDEX,
    redirect: { name: ROUTE_AUTH },
  },
  {
    path: "/auth",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "signin/",
        name: ROUTE_AUTH,
        component: () => import("@/views/AuthView.vue"),
      },
    ],
  },
  {
    path: "/dashboard",
    name: ROUTE_DASH,
    component: () => import("@/views/DashboardView.vue"),
    props: (route) => ({ filterChildId: Number(route.query.filterChildId) }),
  },
  {
    path: "/editform",
    name: ROUTE_EDITFORM,
    component: () => import("@/views/EditForm.vue"),
    props: (route) => ({ id: Number(route.query.id) }),
  },
  {
    path: "/rating",
    name: ROUTE_RATING,
    component: () => import("@/views/RatingForm.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
