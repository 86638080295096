export default {
  setLevels(state, list) {
    state.levels = list;
  },
  setForms(state, list) {
    state.forms = list;
  },
  setTypes(state, list) {
    state.types = list;
  },
  setResults(state, list) {
    state.results = list;
  },
  setDirections(state, list) {
    state.directions = list;
  },
  setDisciples(state, list) {
    state.disciples = list;
  },
  setChildren(state, list) {
    state.children = list;
  },
  setEventsFull(state, list) {
    state.events = list;
  },
  /*
  addCategory(state, data) {
    state.categories.unshift({
      name: data.name,
      category_type: data.category_type,
    });
  },
  */
};
