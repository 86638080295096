import Vue from "vue";
import Axios from "@/api/axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, Axios);

// импорт точек API
import { APIPOINT_HELPLISTS } from "@/api/points";

export const requestGetHelpLists = function () {
  return Axios.get(APIPOINT_HELPLISTS);
};
