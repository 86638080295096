import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import notices from "@/store/modules/notices/index";
import auth from "@/store/modules/auth/index";
import lists from "@/store/modules/lists/index";
import achievements from "@/store/modules/achievements/index";
import helpLists from "@/store/modules/helpLists/index";
const store = new Vuex.Store({
  modules: {
    notices,
    auth,
    lists,
    achievements,
    helpLists,
  },
});

export default store;
