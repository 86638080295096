export const USER_SET_TOKEN = "setToken";
export const USER_SET_ID = "setId";
export const USER_ADDINFO = "addUserInfo";
export const USER_ADDCURINFO = "addCurUserInfo";
export const PUSH_NOTICES = "pushNotices";
export const LIST_SET_LEVELS = "setLevels";
export const LIST_SET_FORMS = "setForms";
export const LIST_SET_TYPES = "setTypes";
export const LIST_SET_RESULTS = "setResults";
export const LIST_SET_DIRECTIONS = "setDirections";
export const LIST_SET_DISCIPLES = "setDisciples";
export const LIST_SET_CHILDREN = "setChildren";
export const LIST_SET_EVENTSFULL = "setEventsFull";
export const LIST_SET_ACH = "setAchievements";
export const ACH_SET_IMAGE = "setImage";
export const HELPLIST_SET_EVENTS = "setEvents";
export const HELPLIST_SET_CITIES = "setCities";
export const HELPLIST_SET_ORGS = "setOrgs";
export const HELPLIST_SET_PATRONS = "setPatrons";
export const HELPLIST_SET_DOCS = "setDocs";
export const SET_RATING = "setRating";
export const SET_RATING_FULL = "setRatingFull";
