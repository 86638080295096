import {
  PUSH_NOTICES,
  LIST_SET_ACH,
  SET_RATING,
  SET_RATING_FULL,
} from "@/store/mutations.type";
import {
  achSubmit,
  achList,
  achCorrect,
  achConfirm,
  achRemove,
  achUnconfirm,
  getRating,
} from "@/api/achRequests";

export default {
  async achievementSubmit({ commit }, data) {
    try {
      const response = await achSubmit(data);
      //commit(LIST_SET_LEVELS, response.data);
      return response;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Не удалось отправить данные достижения" }],
      });
      return false;
    }
  },
  async achievementsList({ commit }, filter) {
    try {
      const response = await achList(filter);
      commit(LIST_SET_ACH, response.data);
      return true;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Не удалось получить список достижений" }],
      });
      return false;
    }
  },
  async achievementCorrect({ commit }, data) {
    try {
      const response = await achCorrect(data.id, data.comment);
      return response.data;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Не удалось обработать запрос" }],
      });
      return false;
    }
  },
  async achievementConfirm({ commit }, id) {
    try {
      const response = await achConfirm(id);
      return response.data;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Не удалось подтвердить запись" }],
      });
      return false;
    }
  },
  async achievementUnconfirm({ commit }, id) {
    try {
      const response = await achUnconfirm(id);
      return response.data;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Не удалось подтвердить запись" }],
      });
      return false;
    }
  },
  async achievementRemove({ commit }, id) {
    try {
      const response = await achRemove(id);
      return response.data;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Не удалось удалить запись" }],
      });
      return false;
    }
  },
  async loadRating({ commit }, id) {
    try {
      const response = await getRating(id);
      if (id != -1) commit(SET_RATING, response.data);
      else commit(SET_RATING_FULL, response.data);
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Не удалось получить рейтинг" }],
      });
      return false;
    }
  },
};
