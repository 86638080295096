import Vue from "vue";
import Axios from "@/api/axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, Axios);

// импорт точек API
import {
  APIPOINT_GET_LEVELS,
  APIPOINT_GET_FORMS,
  APIPOINT_GET_TYPES,
  APIPOINT_GET_RESULTS,
  APIPOINT_GET_DIRECTIONS,
  APIPOINT_GET_DISCIPLES,
  APIPOINT_GET_CHILDREN,
  APIPOINT_GET_EVENTSFULL,
} from "@/api/points";

export const requestGetLevels = function () {
  return Axios.get(APIPOINT_GET_LEVELS);
};
export const requestGetForms = function () {
  return Axios.get(APIPOINT_GET_FORMS);
};
export const requestGetTypes = function () {
  return Axios.get(APIPOINT_GET_TYPES);
};
export const requestGetResults = function () {
  return Axios.get(APIPOINT_GET_RESULTS);
};
export const requestGetDirections = function () {
  return Axios.get(APIPOINT_GET_DIRECTIONS);
};
export const requestGetDisciples = function () {
  return Axios.post(APIPOINT_GET_DISCIPLES);
};
export const requestGetChildren = function () {
  return Axios.post(APIPOINT_GET_CHILDREN);
};
export const requestGetEventsFull = function () {
  return Axios.post(APIPOINT_GET_EVENTSFULL);
};
