import {
  PUSH_NOTICES,
  HELPLIST_SET_EVENTS,
  HELPLIST_SET_CITIES,
  HELPLIST_SET_ORGS,
  HELPLIST_SET_PATRONS,
  HELPLIST_SET_DOCS,
} from "@/store/mutations.type";
import { requestGetHelpLists } from "@/api/helpListsRequests";

export default {
  async loadHelpLists({ commit }) {
    try {
      const response = await requestGetHelpLists();
      commit(HELPLIST_SET_EVENTS, response.data.events);
      commit(HELPLIST_SET_CITIES, response.data.cities);
      commit(HELPLIST_SET_ORGS, response.data.orgs);
      commit(HELPLIST_SET_PATRONS, response.data.patrons);
      commit(HELPLIST_SET_DOCS, response.data.docs);
      return true;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Не удалось получить вспомогательные списки" }],
      });
      return false;
    }
  },
};
