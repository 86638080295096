import Vue from "vue";
import Axios from "@/api/axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, Axios);

// импорт точек API
import {
  APIPOINT_USER_AUTH,
  APIPOINT_USER_CHECKTOKEN,
  APIPOINT_USER_UPDATEPASS,
  APIPOINT_USER_GETUSERINFO,
  APIPOINT_USER_LOGOUT,
} from "@/api/points";

// запрос на аутентификацию пользователя
export const requestUserAuth = function (user, pass) {
  return Axios.post(APIPOINT_USER_AUTH, { user, pass });
};

export const requestGetUserInfo = function (id) {
  return Axios.post(APIPOINT_USER_GETUSERINFO, { id });
};

export const requestUserCheckToken = function (token) {
  return Axios.post(APIPOINT_USER_CHECKTOKEN, { token });
};

export const requestUserLogout = function (token) {
  return Axios.post(APIPOINT_USER_LOGOUT, { token });
};

export const requestUserUpdatePass = function (password) {
  return Axios.post(APIPOINT_USER_UPDATEPASS, { password });
};
