import getters from "@/store/modules/auth/getters";
import mutations from "@/store/modules/auth/mutations";
import actions from "@/store/modules/auth/actions";
export default {
  state: {
    access: "",
    id: 0,
    curUser: {},
    users: [],
  },
  getters,
  mutations,
  actions,
};
