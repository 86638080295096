import {
  PUSH_NOTICES,
  LIST_SET_LEVELS,
  LIST_SET_FORMS,
  LIST_SET_TYPES,
  LIST_SET_RESULTS,
  LIST_SET_DIRECTIONS,
  LIST_SET_DISCIPLES,
  LIST_SET_CHILDREN,
  LIST_SET_EVENTSFULL,
} from "@/store/mutations.type";
import {
  requestGetLevels,
  requestGetForms,
  requestGetTypes,
  requestGetResults,
  requestGetDirections,
  requestGetDisciples,
  requestGetChildren,
  requestGetEventsFull,
} from "@/api/listsRequests";

export default {
  async listLevels({ commit }) {
    try {
      const response = await requestGetLevels();
      commit(LIST_SET_LEVELS, response.data);
      return true;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Не удалось получить список уровней" }],
      });
      return false;
    }
  },
  async listForms({ commit }) {
    try {
      const response = await requestGetForms();
      commit(LIST_SET_FORMS, response.data);
      return true;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Не удалось получить список форм участия" }],
      });
      return false;
    }
  },
  async listTypes({ commit }) {
    try {
      const response = await requestGetTypes();
      commit(LIST_SET_TYPES, response.data);
      return true;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Не удалось получить список типов участия" }],
      });
      return false;
    }
  },
  async listResults({ commit }) {
    try {
      const response = await requestGetResults();
      commit(LIST_SET_RESULTS, response.data);
      return true;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Не удалось получить список результатов" }],
      });
      return false;
    }
  },
  async listDirections({ commit }) {
    try {
      const response = await requestGetDirections();
      commit(LIST_SET_DIRECTIONS, response.data);
      return true;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Не удалось получить список направления" }],
      });
      return false;
    }
  },
  async listDisciples({ commit }) {
    try {
      const response = await requestGetDisciples();
      commit(LIST_SET_DISCIPLES, response.data);
      return true;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Не удалось получить список дисциплин" }],
      });
      return false;
    }
  },
  async listEventsFull({ commit }) {
    try {
      const response = await requestGetEventsFull();
      commit(LIST_SET_EVENTSFULL, response.data);
      return true;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Не удалось получить список мероприятий" }],
      });
      return false;
    }
  },
  async listChildren({ commit }) {
    try {
      const response = await requestGetChildren();
      commit(LIST_SET_CHILDREN, response.data);
      return true;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Не удалось получить список детей" }],
      });
      return false;
    }
  },
};
