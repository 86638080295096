import getters from "@/store/modules/helpLists/getters";
import mutations from "@/store/modules/helpLists/mutations";
import actions from "@/store/modules/helpLists/actions";
export default {
  state: {
    events: [],
    cities: [],
    orgs: [],
    patrons: [],
  },
  getters,
  mutations,
  actions,
};
