import {
  USER_SET_TOKEN,
  PUSH_NOTICES,
  USER_ADDINFO,
  USER_ADDCURINFO,
} from "@/store/mutations.type";
import {
  requestUserAuth,
  requestUserCheckToken,
  requestGetUserInfo,
  requestUserLogout,
} from "@/api/authRequests";

export default {
  async authUser({ commit }, requestData) {
    try {
      const response = await requestUserAuth(
        requestData.username,
        requestData.pass
      );
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = "Token=" + response.data + ";" + expires + ";path=/";
      commit(USER_SET_TOKEN, response.data);
      commit(PUSH_NOTICES, {
        notices: [{ message: "Аутентификация прошла успешно" }],
      });
      return true;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Аутентификация не удалась" }],
      });
      return false;
    }
  },
  async checkToken({ commit }) {
    try {
      const c = document.cookie.split("; ");
      let Token = "";
      for (const i in c) {
        let pair = c[i].split("=");
        if (pair[0] == "Token") Token = pair[1];
      }
      commit(USER_SET_TOKEN, Token);
      const response = await requestUserCheckToken(Token);
      commit(USER_ADDINFO, response.data);
      commit(USER_ADDCURINFO, response.data);
      return response.data.id;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Требуется авторизация" }],
      });
      return false;
    }
  },
  async userInfo({ commit }, id) {
    try {
      const response = await requestGetUserInfo(id);
      commit(USER_ADDINFO, response.data);
      return true;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Ошибка получения информации о пользователе" }],
      });
      return false;
    }
  },
  async userLogout({ commit }) {
    try {
      await requestUserLogout();
      return true;
    } catch (error) {
      commit(PUSH_NOTICES, {
        notices: [{ message: "Ошибка выхода" }],
      });
      return false;
    }
  },
};
