export default {
  getEvents: (state) => {
    return state.events;
  },
  getCities: (state) => {
    return state.cities;
  },
  getOrgs: (state) => {
    return state.orgs;
  },
  getPatrons: (state) => {
    return state.patrons;
  },
  getDocs: (state) => {
    return state.docs;
  },
};
