export default {
  setAchievements(state, list) {
    state.achievements = list;
  },
  setRating(state, rating) {
    state.rating = rating;
  },
  setRatingFull(state, rating) {
    state.ratingFull = rating;
  },
  setImage(state, data) {
    for (let i in state.achievements) {
      if (state.achievements[i].id == data.id) {
        state.achievements[i].image = data.img;
        state.achievements[i].needCorrection = 0;
        state.achievements[i].comment = "";
        return true;
      }
    }
    return false;
  },
};
