export default {
  getLevels: (state) => {
    return state.levels;
  },
  getLevelById: (state) => (id) => {
    return state.levels.find((el) => el.id == id);
  },
  getForms: (state) => {
    return state.forms;
  },
  getFormById: (state) => (id) => {
    return state.forms.find((el) => el.id == id);
  },
  getTypes: (state) => {
    return state.types;
  },
  getTypeById: (state) => (id) => {
    return state.types.find((el) => el.id == id);
  },
  getResults: (state) => {
    return state.results;
  },
  getResultById: (state) => (id) => {
    return state.results.find((el) => el.id == id);
  },
  getDirections: (state) => {
    return state.directions;
  },
  getDirectionById: (state) => (id) => {
    return state.directions.find((el) => el.id == id);
  },
  getDisciples: (state) => {
    return state.disciples;
  },
  getDiscipleById: (state) => (id) => {
    return state.disciples.find((el) => el.id == id);
  },
  getDisciplesByDirection: (state) => (id) => {
    let data = [];
    for (let i in state.disciples) {
      if (state.disciples[i].parentId == id) {
        data.push(state.disciples[i]);
      }
    }
    return data;
  },
  getChildren: (state) => {
    return state.children;
  },
  getEventsFull: (state) => {
    return state.events;
  },
  getChildById: (state) => (id) => {
    return state.children.find((el) => el.id == id);
  },
};
