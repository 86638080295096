export default {
  setToken(state, payload) {
    state.access = payload;
  },
  addUserInfo(state, info) {
    if (!state.users.find((el) => el.id == info.id)) state.users.push(info);
  },
  addCurUserInfo(state, info) {
    state.curUser = info;
  },
  setId(state, id) {
    state.id = id;
  },
};
